import { createSlice } from "@reduxjs/toolkit";

const user = JSON.parse(localStorage.getItem('user'))
const isAdmin = JSON.parse(localStorage.getItem('isAdmin'))

const initialState = {
            isAuthenticated : user?.accessToken,
            isAdmin,
            user,
        }
        // : {
        //         isAuthenticated: false,
        //         user: null,
        //         isAdmin: false
        // }

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        authUserLogin: (state, action) => {
            state.isAuthenticated = true
            state.user = action.payload
        },
        authUserError: (state, action) => {
            state.error = action.payload
        },
        authAdmin: ( state, action ) => {
            state.isAdmin = action.payload
        },
        cleanAuthError: (state) => {
            state.error = null
        },
        authUserLogout: (state) => {
            state.isAuthenticated = false
            state.user = null
        },
        authRefreshToken: (state, action) => {
            state.user = {...user, acessToken: action.payload}
        }
    }
})

export const { authUserLogin, authUserLogout, authUserError,
               authAdmin, authAdminLogout, authAdminError,
               authRefreshToken, cleanAuthError
            } = authSlice.actions

export default authSlice.reducer