import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    maxRentedBooksPerUser: 1,
    maxRentalsOfSameBookPerUser: 1,
    maxDaysToKeepBook: 1,
    message: '',
    errorMessage: '',
    loading: false
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers:{
        setGlobalSettings: (state, action) => {
            state.maxRentedBooksPerUser = action.payload.maxRentedBooksPerUser
            state.maxRentalsOfSameBookPerUser = action.payload.maxRentalsOfSameBookPerUser
            state.maxDaysToKeepBook = action.payload.maxDaysToKeepBook
        },
        setMessage: (state, action) => {
            state.message = action.payload
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload
        },
        cleanMsg: (state) => {
            state.message = ''
            state.errorMessage = ''
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const {setGlobalSettings, setMessage, setErrorMessage, cleanMsg, setLoading} = adminSlice.actions
export default adminSlice.reducer