const setLocalBooks = (books) => {
    localStorage.setItem('rentedBooks', JSON.stringify(books))
}

const getLocalBooks = () => {
    return JSON.parse(localStorage.getItem('rentedBooks'))  
}

const LocalBooks = {
    setLocalBooks,
    getLocalBooks
}

export default LocalBooks