import { combineReducers } from "redux"
import authReducer from './auth/authSlice'
import bookReducer from './books/bookSlice'
import adminReducer from './admin/adminSlice'
import reportReducer from './admin/reportSlice'
import preferencesReducer from './preferences/preferencesSlice'
import profileReducer from '../store/auth/profileSlice'
import booksEditReducer from '../store/admin/booksEditSlice'

export default combineReducers({
    authReducer,
    bookReducer,
    adminReducer,
    profileReducer,
    reportReducer,
    booksEditReducer,
    preferencesReducer,
})
