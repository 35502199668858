import React, { useEffect, lazy, Suspense } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
import CircleLoader from './components/CircleLoader';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import defaultTheme from './components/theme';

const HomePage = lazy(() => import(/* webpackChunkName: "home" */ './pages/HomePage'))
const AdminPage = lazy(() => import(/* webpackChunkName: "admin" */ './pages/AdminPage'))
const LoginPage = lazy(() => import(/* webpackChunkName: "login" */ './pages/LoginPage'))
const ReportLoans = lazy(() => import(/* webpackChunkName: "report-loans" */ './components/ReportLoans'))
const ReportMembership = lazy(() => import(/* webpackChunkName: "report-membership" */ './components/ReportMembership'))
const ReportActivity = lazy(() => import(/* webpackChunkName: "report-activity" */ './components/ReportActivity'))
const Reader = lazy(() => import(/* webpackChunkName: "reader" */ './components/Reader'))
const LoansChart = lazy(() => import(/* webpackChunkName: "loan-charts" */ './components/LoansChart'))
const UserProfile = lazy(() => import(/* webpackChunkName: "profile" */ './components/UserProfile'))
const TermsAndConbditions = lazy(() => import(/* webpackChunkName: "tertms-and-conditions" */ './components/TermsAndConditions'))

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
const localMode = localStorage.getItem('theme')
const themeMode = localMode ? localMode : 'light'

const App = () => {
  const {isAuthenticated, isAdmin} = useSelector(state => state.authReducer)
  const {loans, membership, activity, loading} = useSelector(state => state.reportReducer)
  const navigate = useNavigate()

  const [mode, setMode] = React.useState(themeMode || 'light');
  
  const colorMode = React.useMemo(
      () => ({
      toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      }),
      [],
  );

  useEffect(() => {
    JSON.stringify(localStorage.setItem('theme', mode))
  }, [mode])
    
  const theme = React.useMemo(() => createTheme(defaultTheme(mode)), [mode]);
  
  
  useEffect(() => {
    if(!isAuthenticated && !isAdmin){
      navigate('/login')
    }
    if(!isAuthenticated && isAdmin){
      navigate('/podesavanja')
    }
  },[navigate, isAuthenticated, isAdmin ])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<CircleLoader />}>
          <Routes>
            <Route path='/' element={<HomePage />}/>
            <Route exact path='login' element={<LoginPage />}/>
            <Route exact path='/podesavanja' element={<AdminPage />} />
            <Route exact path='/reports/loans' element={<ReportLoans loans={loans} loading={loading}/>} />
            <Route exact path='/reports/membership' element={<ReportMembership membership={membership} loading={loading}/>} />
            <Route exact path='/reports/activity' element={<ReportActivity activity={activity} loading={loading}/>} />
            <Route exact path="/r/:link" element={<Reader />} />
            <Route exact path='/reports/charts' element={<LoansChart loans={loans} loading={loading} />} />
            <Route exact path='/profile/:id' element={<UserProfile/>} />
            <Route exact path='/policy' element={<TermsAndConbditions/>} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </ColorModeContext.Provider>
    
  );
}

export default App
