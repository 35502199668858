import api from '../auth/api'
import { retrieveRentedBooks, onBookSuccess, onBookError, isLoading, isAddBookLoading, setHasBooks, retrieveBooksList } from './bookSlice'
import LocalBooks from './localBooks'

const addBook = (formData) => async dispatch => {
    dispatch(isAddBookLoading(true))
    api.post('/books/register', formData)
        .then(res => {
            if(res.status === 200){
                dispatch(onBookSuccess('Uspešno ste dodali knjigu'))
                dispatch(isAddBookLoading(false))
            }
        })
        .catch(err => {       
            dispatch(onBookError('Ova knjiga već postoji u sistemu'))
            dispatch(isAddBookLoading(false))
        })
}

const returnBook = ({library, memberId, cobissId, rating}) => async dispatch => {
    const acronym = library
    const member_id = memberId
    const cobiss_id = cobissId
    try{
        const res = await api.post('/rents/return', {acronym, member_id, cobiss_id, rating})
        if(res.status === 200){
            dispatch(isAddBookLoading(true))
            dispatch(getRentedBooks(memberId))
        }
    }catch(err){
        return Promise.reject(err)
    }
}

const getBooksList = (memberId) => async dispatch => {
    api.get(`/books/vacant/${memberId}`)
        .then((res) => {
            const books = res.data.map(({cobissId, title, author, thumbnail}) => ({
                cobissId,
                title,
                author,
                thumbnail
            }))
            dispatch(retrieveBooksList(books))
            dispatch(isLoading(false))
        })
        .catch((err) => {
            dispatch(isLoading(false))
        })
}

const getRentedBooks = (memberId) => async dispatch => {
    api.get(`/rents/user/${memberId}`)
        .then((res) => {
            if(res.data.length){
                dispatch(retrieveRentedBooks(res.data))
                dispatch(setHasBooks(true))
                dispatch(isLoading(false))
            }else{
                dispatch(setHasBooks(false))
                dispatch(isLoading(false))
            }
            LocalBooks.setLocalBooks(res.data)
        })
        .catch((err) => {
            dispatch(isLoading(false))
        })
}

const BookService = {
    addBook,
    returnBook,
    getBooksList,
    getRentedBooks
}

export default BookService