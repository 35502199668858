import { Box, CircularProgress } from '@mui/material'
import React from 'react'


const CircleLoader = () => {
    return (
        <Box component="div" sx={{display: 'flex', justifyContent:'center', alignItems:'center', height:'70vh'}}><CircularProgress /></Box>
    )
}

export default CircleLoader