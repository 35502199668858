import { deepPurple, grey } from "@mui/material/colors";

const defaultTheme = (mode) => ({
    palette: {
      mode,
      ...(mode !== 'light' && {
            // palette values for dark mode
            primary: grey,
            divider: deepPurple[700],
            // background: {
            //   default: deepOrange[900],
            //   paper: deepOrange[900],
            // },
            text: {
              primary: '#fff',
              secondary: grey[500],
            },
          }),
    },
  });

  export default defaultTheme