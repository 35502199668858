import api from '../api'
import {TokenService} from './token.services'
import { authUserLogin, authUserLogout, cleanAuthError, authAdmin, authUserError } from '../authSlice'
import { authRefreshToken } from '../authSlice'
import { retrieveRentedBooks } from '../../books/bookSlice'
import { cleanProfile } from '../services/profile.services'

const login = (library, memberId, password, rememberMe) => async (dispatch) => {
    if(!memberId && !password){
        dispatch(authUserError('Unesite obavezna polja'))
        return 
    }
    dispatch(authAdmin(false))
    return api
        .post('/authenticate',{
            library,
            memberId,
            password,
        })
        .then((res) => {
            const role = res.data.roles.filter(r => r === 'ROLE_ADMIN').length ? true : false
            dispatch(authUserLogin(res.data))
            dispatch(authAdmin(role))
            dispatch(cleanAuthError())
            TokenService.setUser(res.data)
            TokenService.setIsAdmin(role)
        })
        .catch(err => err)
}

const logout = () => async dispatch => {
        const user = TokenService.getUser()
        const admin = TokenService.getIsAdmin()
        if(user){
            api.post('/logout', {memberId: user.memberId, refreshToken: user.refreshToken }, {headers:{'Authorization': `Bearer ${user.accessToken}`}})
            .then(res => {
                dispatch(authUserLogout())
                TokenService.removeUser()
            })
            .catch(err => {
                dispatch(authUserLogout())
                TokenService.removeUser()
            })
        }
        dispatch(authUserLogout())
        if(admin){
            dispatch(authAdmin(true))
        }
        dispatch(cleanProfile())
        dispatch(retrieveRentedBooks([]))
        TokenService.removeUser()
        if(window.location.href.includes('r/')){
            console.log(window.location.href)
            window.location.href = '/login'
        }
}

const refreshToken = (accessToken) => (dispatch) => {
    dispatch(authRefreshToken(accessToken))
}

const UserAuthService = {
    login,
    logout,
    refreshToken
}

export default UserAuthService
