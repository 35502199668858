const getLocalRefreshToken = () => {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.refreshToken;
    }
  
const getLocalAccessToken = () => {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.accessToken;
    }
  
const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  }

const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  }

const getIsAdmin = () => {
  return JSON.parse(localStorage.getItem("isAdmin"));
  }
  
const setIsAdmin = (role) => {
    localStorage.setItem("isAdmin",JSON.stringify(role))
  }

const removeUser = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("isAdmin")
    localStorage.removeItem("rentedBooks")
    
  }
  
export const TokenService = {
  getLocalAccessToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  getIsAdmin,
  setIsAdmin,
  removeUser
}