import { createSlice } from "@reduxjs/toolkit";

const preferences = JSON.parse(localStorage.getItem('preferences'))
const pdfPreferences = JSON.parse(localStorage.getItem('pdfpreferences'))
const initialState =  { 
        books:  preferences ? preferences : [],
        pdfbooks: pdfPreferences ? pdfPreferences : []
    }
const preferencesSlice = createSlice({
    name: 'preferences',
    initialState: initialState,
    reducers: {
        pushPreferences: (state, action) => {
            let user = state.books.filter(item => item.userId === action.payload.userId)[0]
            if (!user) {
                state.books = [...state.books, action.payload]
                localStorage.setItem('preferences', JSON.stringify(state.books))
            } else {
                user.book = user.book.filter(item => item.id !== action.payload.book[0].id)
                user.book = [...user.book, action.payload.book[0]]
                state.books = state.books.filter(item => item.userId !== user.userId)
                state.books = [...state.books, user]
                localStorage.setItem('preferences', JSON.stringify(state.books))
            }
        },
        pushPdfPreferences: (state, action) => {
            let user = state.pdfbooks.filter(item => item.userId === action.payload.userId)[0]
            if (!user) {
                state.pdfbooks = [...state.pdfbooks, action.payload]
                localStorage.setItem('pdfpreferences', JSON.stringify(state.pdfbooks))
            } else {
                user.pdfbook = user.pdfbook.filter(item => item.id !== action.payload.pdfbook[0].id)
                user.pdfbook = [...user.pdfbook, action.payload.pdfbook[0]]
                state.pdfbooks = state.pdfbooks.filter(item => item.userId !== user.userId)
                state.pdfbooks = [...state.pdfbooks, user]
                localStorage.setItem('pdfpreferences', JSON.stringify(state.pdfbooks))
            }
        }
    }
})

export const PreferencesActions = preferencesSlice.actions

export default preferencesSlice.reducer