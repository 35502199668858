import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    firstName: null,
    lastName: null,
    email: null,
    profileMessage: {}
}

const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers:{
        getUserProfile: (state) => {
            return {...state}
        },
        updateUserProfile: (state, action) => {
            return state = {...action.payload}
        },
        setProfileMessage: (state, action) => {
            state.profileMessage = action.payload
        }
    }
})

export const {getUserProfile, updateUserProfile, setProfileMessage} = profileSlice.actions
export default profileSlice.reducer