import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    books: null,
    loading: false,
    statusMessage: {
        status: null,
        message: null
    }
}

const booksEditSlice = createSlice({
    name: 'booksEdit',
    initialState,
    reducers: {
        getBookList: (state) => {
            return state.books
        },
        setBookList: (state, action) => {
            state.books = [...action.payload]
        },
        cleanBookList: (state, action) => {
            state.books = action.payload
        },
        isLoading: (state, action) => {
            state.loading = action.payload
        },
        onMessage: (state, action) => {
            state.statusMessage = action.payload
        },
    }
})

export const {getBookList, setBookList, isLoading, onMessage, cleanBookList} = booksEditSlice.actions
export default booksEditSlice.reducer

