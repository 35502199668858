import { createSlice } from "@reduxjs/toolkit";
import LocalBooks from "./localBooks";

const books = LocalBooks.getLocalBooks()

const initialState = {
    booksList: [],
    rentedBooks: books || [],
    hasBooks: null,
    addedBookMessage: '',
    errorBookMessage: '',
    loading: true,
    addBookLoading: false
}

export const bookSlice = createSlice({
    name: 'book',
    initialState,
    reducers:{
        retrieveBooksList: (state, action) => {
            state.booksList = action.payload
        },
        retrieveRentedBooks: (state, action) => {
            state.rentedBooks = action.payload
        },
        isLoading: (state, action) => {
            state.loading = action.payload
        },
        onBookSuccess: (state, action) => {
            state.addedBookMessage = action.payload
        },
        onBookError: (state, action) => {
            state.errorBookMessage = action.payload
        },
        cleanMessages: (state) => {
            state.addedBookMessage = ''
            state.errorBookMessage = ''
        },
        setHasBooks: (state , action) => {
            state.hasBooks = action.payload
        },
        setCurrentBook: (state, action) => {
            state.currentBook = state.rentedBooks.filter(item => `${item.book.cobissId}` === `${action.payload}`)[0]
        },
        isAddBookLoading: (state, action) => {
            state.addBookLoading = action.payload
        }
    }
})
export const { retrieveBooksList, retrieveRentedBooks, onBookSuccess, onBookError, isLoading, setHasBooks, setCurrentBook, cleanMessages, isAddBookLoading} = bookSlice.actions
export default bookSlice.reducer


