import api from './api'
import axios from 'axios'
import {TokenService} from './services/token.services'
import UserAuthService from './services/auth.services'
import BookService from '../books/books.services'
import { isAddBookLoading, onBookError } from '../books/bookSlice'
import { authUserError } from './authSlice'


const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_PROD_URL : process.env.REACT_APP_BASE_DEV_URL

const setup = (store) => {
    api.interceptors.request.use(
        (config) => {
            const user = TokenService.getUser()
            const isLoggedIn = user?.accessToken
            if(isLoggedIn){
                config.headers['Authorization'] = `Bearer ${user.accessToken}`
                config.withCredentials = true
                if(!config.data){
                    config.data = {}
                }
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    );
    
    const {dispatch} = store

    api.interceptors.response.use(
        (res) => {
            res.config.headers['set-cookie'] = 'SameSite=None; Secure' 
            return res
        },
        async (err) => {
                const originalConfig = err.config
                const user = TokenService.getUser()
                const isLoggedIn = user?.accessToken
                if(err.response && (originalConfig.url !== '/authenticate') && isLoggedIn){
                    if(err.response.status === 401 && !originalConfig._retry){                         
                        originalConfig._retry = true
                        try{
                            axios.post(`${url}/refreshtoken`,{
                                refreshToken: TokenService.getLocalRefreshToken()
                            })
                            .then(res => {
                                const {accessToken} = res.data
                                dispatch(UserAuthService.refreshToken(accessToken))
                                TokenService.updateLocalAccessToken(accessToken)
                                return api(originalConfig)
                            })
                            .catch(err => {
                                dispatch(UserAuthService.logout())
                            })          
                        }catch(_error){
                            return Promise.reject(_error)
                        }
                    }
                    return Promise.reject(err)
                }

                if(err.response && err.response.status === 400 && err.config && originalConfig.url === '/books/register' && !err.config.__isRetryRequest){
                    originalConfig._retry = true
                    dispatch(onBookError('Ova knjiga već postoji u sistemu'))
                    dispatch(isAddBookLoading(false))
                    return err
                }

                if(err.response && err.response.status === 404 && err.config && originalConfig.url === '/rents/return' && !err.config.__isRetryRequest){
                    originalConfig._retry = true
                    const {memberId} = TokenService.getUser()
                    dispatch(onBookError('Knjiga je več vračena'))
                    dispatch(isAddBookLoading(false))
                    dispatch(BookService.getRentedBooks(memberId))
                    return err
                }

                if(err.response && err.response.status === 403 && err.config && originalConfig.url === '/authenticate' && !err.config.__isRetryRequest){
                    originalConfig._retry = true
                    let msg = 'Istekla Vam je članarina'
                    dispatch(authUserError(msg))
                    return err
                }else if (err.response && (err.response.status === 404 || err.response.status === 401) && err.config && originalConfig.url === '/authenticate' && !err.config.__isRetryRequest){
                    originalConfig._retry = true
                    let msg = 'Uneli ste pogrešne kredencijale'
                    dispatch(authUserError(msg))
                    return err
                }else if (err.response && err.response.status === 500 && err.config && originalConfig.url === '/authenticate' && !err.config.__isRetryRequest){
                    originalConfig._retry = true
                    let msg = 'Došlo je do greške, pokušajte kasnije'
                    dispatch(authUserError(msg))
                    return err
                } else if (err.response && err.response.status === 400 && err.config && originalConfig.url === '/authenticate' && !err.config.__isRetryRequest) {
                    originalConfig._retry = true
                    let msg = 'Korisnik ne postoji'
                    dispatch(authUserError(msg))
                    return err
                }

                if(err.response && (err.response.status === 400 || err.response.status === 401 || err.response.status === 403 || err.response.status === 404) 
                    && err.config && originalConfig.url === '/refreshtoken' && !err.config.__isRetryRequest){
                    originalConfig._retry = true
                    dispatch(UserAuthService.logout())
                    return err
                }

                if(err.response && err.response.status === 404 && err.config && originalConfig.url === '/logout' && !err.config.__isRetryRequest){
                    originalConfig._retry = true
                    dispatch(UserAuthService.logout())
                    return err
                }
            return Promise.reject(err)
        }
    )
}

export default setup