import { setProfileMessage, updateUserProfile } from "../profileSlice";
import api from '../api'

export const fetchUserProfile = (id) => dispatch => {
    api.get(`/users/${id}`)
        .then(res => {
            const {firstName, lastName, email, phone} = res.data
            dispatch(updateUserProfile({firstName, lastName, email, phone}))
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

export const updateProfile = (userProfile) => dispatch => {
    const { memberId, firstName, lastName, email, phone } = userProfile
    let emailField = null
    if(email !== ''){
        emailField = email
    }
    api.patch(`/users/${memberId}`, {
        firstName, lastName, email: emailField, phone
    })
        .then(res => {
            dispatch(updateUserProfile({firstName, lastName, email, phone}))
            dispatch(setProfileMessage({status: 'ok', message: 'Promene su bile uspešno sačuvane'}))       
        })
        .catch(err => {
            dispatch(setProfileMessage({status: 'error', message: 'Ta email več postoji u sistemu'}))
        })
}

export const cleanProfile = () => dispatch => {
    dispatch(updateUserProfile({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        profileMessage: null
    }))
}