import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    loans: null,
    membership: null,
    activity: null,
    loading: false
}

const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers:{
        setBookLoans: (state, action) => {
            state.loans = [...action.payload]
        },
        setMembership: (state, action) => {
            state.membership = [...action.payload]
        },
        setActivity: (state, action) => {
            state.activity = [...action.payload]
        },
        getBookReport: (state, action) => {
            const newState = state
            return newState.filter(book => book.book.cobissId === Number(action.payload.id))
        },
        isLoading: (state, action) => {
            state.loading = action.payload
        },
        cleanReports: (state) => {
            return initialState
        }
    },
})

export const {setBookLoans, setMembership, setActivity, getBookReport, isLoading, cleanReports} = reportSlice.actions
export default reportSlice.reducer
