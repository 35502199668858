import axios from "axios"


const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_PROD_URL : process.env.REACT_APP_BASE_DEV_URL   

const instance = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
    },
})

export default instance


